import React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { handleCarDataAutofil } from '../AddVehicleActions';
import FormInputField from '../../common/components/formFields/FormInputField';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';

const ConfirmNumber = ({ nextForm }) => {
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const basicData = useSelector(state => state.addVehicle.get('basicData'));
  const modelName = basicData?.modelName;
  const makeName = basicData?.makeName;
  const dispatch = useDispatch();

  const moveToNextStep = (e, accepted = false) => {
    dispatch(handleCarDataAutofil(accepted));
    nextForm();
  };

  return (
    <>
      <AddVehicleHeader
        hideBackButton={true}
        nextButtonHandler={e => moveToNextStep(e, false)}
        nextButtonDisabled={!!isLoading}
        hideSaveDraftButton={true}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <Row>
              <Col>
                <h5 className="font-weight-bold m-0">Registration Number</h5>
                <p className="text-sm mt-2">
                  Please enter your vehicle registration to start a new listing.
                </p>
              </Col>
            </Row>
            <Row className="align-items-center text-left mt-3">
              <Col sm={12} md={12}>
                <FormInputField
                  inputClassName="add-number-form__input-field"
                  buttonClassNames="add-number-form__search-button"
                  wrapperClassName="add-number-form__input-wrapper"
                  name="basicData.registrationPlate"
                  type="text"
                  label="Vehicle registration plate"
                  upperCase
                  withButton={true}
                  buttonLabel="Search"
                  buttonDisabled={true}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Alert color="info">
                  <div>
                    <p className="m-0">Vehicle found:</p>
                    <p className="m-0 font-weight-bold">
                      {makeName} {modelName}
                    </p>
                    <p className="m-0">Do you want to complete the data automatically?</p>
                  </div>
                </Alert>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="actions-wrapper" md={6}>
                <CommonButton
                  label="no"
                  handleClick={e => moveToNextStep(e, false)}
                  variant={CommonButtonVariants.OUTLINED}
                  className="flex-grow-1"
                />
                <CommonButton
                  label="yes"
                  handleClick={e => moveToNextStep(e, true)}
                  className="flex-grow-1"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </AddVehicleContentWrapper>
    </>
  );
};

ConfirmNumber.propTypes = {
  nextForm: PropTypes.func.isRequired,
};

export default ConfirmNumber;
