import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Nav, NavItem, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { CommonButtonSize } from '../constants/CommonButtonVariants';
import CommonButton from '../common/components/CommonButton';
import { AuctionStatusName } from '../constants/AuctionStatus';

const { WAITING, ...AuctionStatusList } = AuctionStatusName;

const MyVehiclesHeader = ({ handlerChange }) => {
  const selected = useSelector(state => state.myVehicles.get('filter'));
  const location = useLocation();

  const handleClick = status => {
    if (status === selected) return;
    handlerChange(status);
    if (location?.state?.filter) {
      location.state.filter = null;
    }
  };

  return (
    <Row className="mb-2 mt-2">
      <Col>
        <Nav className="d-flex flex-row flex-wrap my-vehicles-header">
          {/* <NavItem className="my-vehicles-header__item"> */}
          {/*  <CommonButton */}
          {/*    label="All" */}
          {/*    className={`${selected === '' ? 'selected' : ''} position-relative text-sm`} */}
          {/*    handleClick={() => handleClick('')} */}
          {/*    size={CommonButtonSize.MEDIUM} */}
          {/*  /> */}
          {/* </NavItem> */}
          {Object.keys(AuctionStatusList).map(status => (
            <NavItem className="my-vehicles-header__item" key={status}>
              <CommonButton
                label={
                  AuctionStatusName[status] === AuctionStatusName.REJECTED
                    ? 'DECLINED'
                    : AuctionStatusName[status]
                }
                className={`${
                  selected === status || status === location?.state?.filter ? 'selected' : ''
                } position-relative text-sm`}
                handleClick={() => handleClick(status)}
                size={CommonButtonSize.MEDIUM}
              />
            </NavItem>
          ))}
        </Nav>
      </Col>
    </Row>
  );
};

MyVehiclesHeader.propTypes = {
  handlerChange: PropTypes.func.isRequired,
};

export default MyVehiclesHeader;
