export const prepareVehicleTitle = vehicle => {
  const make = vehicle?.vehicleModel?.makeName;
  const model = vehicle?.vehicleModel?.name;
  const derivative = vehicle?.derivative;

  if (make && model && derivative) {
    const label = `${make} ${model} ${derivative}`;
    const pattern = /\b(\w)(\w*)\b/g;
    const replacement = (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase();
    const result = label.replace(pattern, replacement);
    // eslint-disable-next-line no-useless-escape
    const specialCharsRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    const parts = result.split(' ').map(part => {
      if (part.length <= 2 || /\d/.test(part)) return part.toUpperCase();
      if (upperCaseNames.includes(part.toUpperCase()) || part.match(specialCharsRegex))
        return part.toUpperCase();
      return part;
    });
    return parts.join(' ').toUpperCase();
  }
  return null;
};

const upperCaseNames = [
  'BMW',
  'FBS',
  'KTM',
  'TVR',
  'LDV',
  'DFSK',
  'DAF',
  'MAN',
  'GTV',
  'BLS',
  'CTS',
  'SRX',
  'STS',
  'XLR',
  'GTS',
  'XKR',
  'MPV',
  'TDI',
  'JDJ',
  'HSE',
  'CLS',
  'CRX',
  'FDF',
  'GTV',
  'DBS',
  'GTC',
  'BLS',
  'CTS',
  'SRX',
  'STS',
  'XLR',
  'GTS',
  'GTB',
  'MPW',
  'CRX',
  'NSX',
  'XJR',
  'XJS',
  'XKR',
  'MPV',
  'CLC',
  'CLK',
  'CLS',
  'GLC',
  'GLS',
  'SLC',
  'SLK',
  'SLS',
  'MGF',
  'RXH',
  'RCZ',
  'SST',
  'BRZ',
  'SVX',
  'WRX',
  'GTC',
  'NKR',
  'NKR(W)',
  'NKR-S',
  'NKR-T',
  'GLC',
  'TGL',
  'TGA',
  'TGM',
  'TGS',
  'TGX',
  'CBR',
  'PLA',
  'VIP',
  'CCM',
  'LBX',
  'GLB',
];
