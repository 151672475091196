export const VehicleServiceHistory = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL',
  NONE: 'NONE',
};

export const VehicleServiceHistoryName = {
  FULL: 'Full Service History',
  PARTIAL: 'Partial Service History',
  NONE: 'No Service History',
};
