import React from 'react';
import { Row, Col, Container } from 'reactstrap';

import phoneClockIcon from 'assets/img/phone-clock-icon.png';
import phoneRocketIcon from 'assets/img/phone-rocket-icon.png';
import userShieldedIcon from 'assets/img/user-shield-icon.png';
import clockIcon from 'assets/img/clock-icon.png';

const BuyCard = ({ icon, title, description, bordered }) => {
  return (
    <div className={`buy-card-wrapper h-100 ${bordered ? 'bordered' : ''}`}>
      <div className="buy-card">
        <Row>
          <Col>
            <div className="buy-card__image">
              <img src={icon} alt={title} width="72px" className="m-0" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="buy-card__title fw-700 text-xl">{title}</Col>
        </Row>
        <Row>
          <Col className="but-card__description text-base">{description}</Col>
        </Row>
      </div>
    </div>
  );
};

const BuySection = () => {
  return (
    <section className="buy section" id="buy-section">
      <Container fluid>
        <Row>
          <Col className="buy-content" md={12}>
            <Row className="buy-content__title-container mb-4">
              <Col sm={12} className="pl-0">
                <h5 className="color-light-primary text-lg font-weight-bolder">BUY</h5>
                <h5 className="font-weight-bold section-title">
                  Buy vehicles faster and receive instant notifications when vehicles are
                  added.
                </h5>
              </Col>
              {/* <Col sm={12} md={6} lg={6} className="pr-0 fw-400 text-base color-primary"></Col> */}
            </Row>
            <Row className="buy-content__card-list-container ml-auto mr-auto">
              <Col sm={12} md={12} lg={3} className="p-0">
                <BuyCard
                  title="Smart Listings"
                  description="Buy used vehicle stock faster and smarter. Buy in Hours not Days!"
                  icon={phoneClockIcon}
                  bordered={true}
                />
              </Col>
              <Col sm={12} md={12} lg={3} className="p-0">
                <BuyCard
                  title="Innovative Mobile App"
                  description="Our innovative Mobile App delivers a seamless buying experience with fully detailed appraisals and vehicle images."
                  icon={phoneRocketIcon}
                  bordered={true}
                />
              </Col>
              <Col sm={12} md={12} lg={3} className="p-0">
                <BuyCard
                  title="Professional Dealers"
                  description="Buying with confidence is key. All vehicles offered are exclusively from Franchised Dealers."
                  icon={userShieldedIcon}
                  bordered={true}
                />
              </Col>
              <Col sm={12} md={12} lg={3} className="p-0">
                <BuyCard
                  title="Instant Notifications"
                  description="The listings may be fast, but you won't miss the chance to buy as you receive push notifications for all vehicles offered direct to your mobile device."
                  icon={clockIcon}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BuySection;
