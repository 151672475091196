import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import * as Yup from 'yup';
import { acceptByBuyer, rejectByBuyer } from '../../myvehicles/MyVehiclesActions';
import { AuctionStatus } from '../../constants/AuctionStatus';
import CommonButton from './CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import FormInputField from './formFields/FormInputField';
import { fetchOrganizationDetails } from '../../settings/SettingsActions';
import FormCurrencyInputField from './formFields/FormCurrencyInputField';
import { preparePriceFormat } from '../helpers/preparePriceFormat';
import { prepareBidsData } from '../helpers/prepareBidsData';
import { placeBid } from '../../search/SearchActions';
import CommonErrorLabel from './CommonErrorLabel';
import { isDateBefore } from '../utils/date/Date.utils';
import { BidStatusTypeName } from '../../constants/BidStatusType';
import { StaticValues } from '../../constants/StaticValues';
import {
  AUCTION_BID_MAX_BIDS_LIMIT,
  AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE,
  AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE,
} from '../../constants/AuctionBid';
import { ordinalSuffix } from '../helpers/ordinalSuffix';
import SearchConfirmBidModal from '../../search/bidModals/SearchConfirmBidModal';
import { validateBidAmount } from '../helpers/AuctionBidHelper';
import { pencesToPounds } from '../helpers/pencesToPounds';

const AuctionDetailsBuyerSection = ({ item, refreshItem, bids, status, changeStatus }) => {
  const bidFormRef = useRef(null);
  const dispatch = useDispatch();
  const [validationRequiredBid, setValidationRequiredBid] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [bidToSend, setBidToSend] = useState(0);
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  const bidErrorMessage = useSelector(state => state.search.get('bidErrorMessage'));
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const sellerOrganizationId = item?.seller?.organizationContact?.id;
  const [bidsNotifications, setBidsNotifications] = useState({});
  const bidsData = bids && bids.length > 0 ? bids : item?.bids;
  const [bidsLeft, setBidsLeft] = useState(AUCTION_BID_MAX_BIDS_LIMIT - bidsData.length);

  useEffect(() => {
    dispatch(fetchOrganizationDetails());
  }, [dispatch]);

  useEffect(() => {
    if (status) {
      changeStatus(false);
      refreshItem();
      setBidsNotifications({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const {
    bidMyOrganizationIsHighest,
    highestBidMyOrganization,
    bidsMyOrganization,
  } = prepareBidsData(bidsData, organizationId);

  const handleAcceptAuction = () => {
    dispatch(
      acceptByBuyer(item, () => {
        refreshItem();
      }),
    );
  };

  const handleRejectAuction = () => {
    dispatch(
      rejectByBuyer(item, () => {
        refreshItem();
      }),
    );
  };
  const renderBidders = () => {
    return (
      bidsMyOrganization &&
      bidsMyOrganization.length &&
      bidsMyOrganization
        .filter(item => item.bidderOrganizationId === organizationId)
        .sort((a, b) => a.amountInPence - b.amountInPence)
        .map(({ bidderFirstName, bidderLastName, id, amountInPence }, index) => {
          const bidderFullName =
            bidderFirstName && bidderLastName ? `${bidderFirstName} ${bidderLastName}` : '-';
          const preparedBidder = `${ordinalSuffix(
            index + 1,
          )} bid: ${bidderFullName} ${preparePriceFormat(amountInPence, true)}`;
          return (
            <li key={id}>
              <i className="fas fa-angle-right auction-detail-buyer__arrow-icon" />
              {preparedBidder}
            </li>
          );
        })
    );
  };

  const addNotification = (id, message, className) => {
    const notifications = cloneDeep(bidsNotifications);
    notifications[id] = {
      className,
      message,
    };
    setBidsNotifications(notifications);
  };

  const handleBid = async () => {
    await dispatch(
      placeBid(item?.id, bidToSend * 100, response => {
        const { success, auctionId, result } = response;
        setBidsLeft(result?.bidsLeft);
        if (!success) {
          addNotification(auctionId, result, AuctionStatus.PUBLISHED);
        } else {
          let message;
          let color;

          const bidAmount = `£${bidToSend.toLocaleString('en-GB')}`;

          if (result.isHighestBid) {
            if (result.proxyBidInPence) {
              message = `Your bid of ${result.proxyBidInPence} is the highest. Your maximum bid is ${bidAmount}`;
            } else {
              message = `Your bid of ${bidAmount} is the highest`;
            }
            color = AuctionStatus.PUBLISHED;
          } else {
            message = `Your bid of ${bidAmount} is not the highest - try again`;
            color = AuctionStatus.REJECTED;
          }
          addNotification(auctionId, message, color);
        }
      }),
    );
    setShowConfirm(false);
    if (bidsLeft !== 1) {
      bidFormRef.current.resetForm();
    }
    bidFormRef.current.setSubmitting(false);
  };

  const handleSubmit = async ({ bid }) => {
    setBidToSend(bid);
    setShowConfirm(true);
  };

  const renderBidMessage = () => {
    let message;
    let statusColor;
    if (bidMyOrganizationIsHighest) {
      if (item.proxyBidInPence) {
        message = `Your bid of ${preparePriceFormat(
          item.proxyBidInPence,
          true,
        )} is the highest. Your maximum bid is ${preparePriceFormat(
          highestBidMyOrganization?.amountInPence,
          true,
        )}`;
      } else {
        message = `Your bid of ${preparePriceFormat(
          highestBidMyOrganization?.amountInPence,
          true,
        )} is the highest`;
      }
      statusColor = AuctionStatus.PUBLISHED;
    } else if (bidsMyOrganization?.length === AUCTION_BID_MAX_BIDS_LIMIT) {
      message = `Your bid of ${preparePriceFormat(
        highestBidMyOrganization?.amountInPence,
        true,
      )} was not the highest`;
      statusColor = AuctionStatus.REJECTED;
    } else {
      message = `Your bid of ${preparePriceFormat(
        highestBidMyOrganization?.amountInPence,
        true,
      )} is not the highest - try again`;
      statusColor = AuctionStatus.REJECTED;
    }
    return (
      <CommonButton
        label={message}
        variant={CommonButtonVariants.AUCTION}
        color={statusColor}
      />
    );
  };

  const validationSchema = Yup.object().shape({
    bid: Yup.number()
      .required('Amount value is required')
      .integer('Bid must be an integer')
      .test(
        'required bid amount',
        `Your bid must be greater than or equal to ${preparePriceFormat(
          validationRequiredBid,
        )}`,
        value =>
          validateBidAmount(value, item, highestBidMyOrganization, setValidationRequiredBid),
      ),
  });

  const highestBid = item?.bids?.find(item => item.isHighest);

  const getInitBidValue = useCallback(() => {
    const isCurrentBidderWinning = item.bids.some(it => it.isHighest);
    if (item?.proxyBidInPence && !isCurrentBidderWinning) {
      return item?.proxyBidInPence / 100 + pencesToPounds(AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE);
    }
    if (item?.highestBidInPence) {
      return (
        item?.highestBidInPence / 100 + pencesToPounds(AUCTION_BID_MINIMUM_AMOUNT_IN_PENCE)
      );
    }
    if (item?.minimumPriceInPence) {
      return pencesToPounds(item?.minimumPriceInPence);
    }
    return pencesToPounds(AUCTION_MINIMUM_START_BID_AMOUNT_IN_PENCE);
  }, [item]);

  const bidInitValue = getInitBidValue();

  return (
    <>
      {!isDateBefore(item?.endsOn, new Date()) ||
      item.bids.some(item => item.bidderOrganizationId === organizationId) ? (
        <>
          {(() => {
            switch (item.status) {
              case AuctionStatus.PUBLISHED:
                return (
                  <Row className="auction-detail-buyer-wrapper d-flex justify-content-start">
                    <Formik
                      innerRef={bidFormRef}
                      enableReinitialize
                      initialValues={{ bid: '' }}
                      onSubmit={handleSubmit}
                      validationSchema={validationSchema}
                      validateOnChange
                      validateOnBlur={false}
                    >
                      {() => (
                        <Form className="auction-detail-bid__form">
                          <Row className="mt-2 p-3">
                            {item.isBiddingAllowed && (
                              <Col md={6} className="auction-detail-bid__form-inner-wrapper">
                                <p className="text-center mt-2 mb-0 font-weight-bold">
                                  Last bid from your organisation:
                                </p>
                                <Row className="d-flex justify-content-center align-items-start pb-2">
                                  <Col md={6} className="p-0">
                                    <FormCurrencyInputField
                                      inputClassName="font-weight-bold"
                                      maxLength={14}
                                      name="bid"
                                      placeholder={`Minimum ${preparePriceFormat(
                                        bidInitValue,
                                        false,
                                      )}`}
                                      label="Enter your maximum bid"
                                      disabled={isLoading || bidsLeft === 0}
                                    />
                                    <CommonErrorLabel value={bidErrorMessage} />
                                  </Col>
                                  <Col
                                    md={6}
                                    className="d-flex align-items-center align-self-center p-0 buyer-section__send-button-wrapper"
                                  >
                                    <CommonButton
                                      disabled={isLoading || bidsLeft <= 0}
                                      type="submit"
                                      label="Bid"
                                      className="buyer-section__send-button"
                                    />
                                  </Col>
                                </Row>
                                <Row className="pb-2">
                                  {bidsData.length > 0 && renderBidMessage()}
                                </Row>
                              </Col>
                            )}
                            <Col md={6} className="text-center">
                              {bidsData.length && sellerOrganizationId !== organizationId ? (
                                <>
                                  <p className="auction-detail-buyer__section-title mb-2">
                                    Bids from your organisation:
                                  </p>
                                  <ul>{renderBidders()}</ul>
                                </>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Row>
                );
              case AuctionStatus.REJECTED:
                return (
                  <Row className="auction-detail-buyer-wrapper d-flex justify-content-start">
                    {bidsData.length && sellerOrganizationId !== organizationId ? (
                      <Formik initialValues={{ bid: '' }} onSubmit={null}>
                        {() => (
                          <Form className="auction-detail-bid__form">
                            <Row>
                              <Col md={6}>
                                <p className="text-center mt-2 mb-0 font-weight-bold">
                                  Last bid from your organisation:
                                </p>
                                <Row className="d-flex justify-content-center">
                                  <Col className="p-0">
                                    <FormCurrencyInputField
                                      inputClassName="font-weight-bold"
                                      name="bid"
                                      disabled
                                      className="auction-detail-bid__currency-input"
                                      placeholder={preparePriceFormat(
                                        highestBidMyOrganization?.amountInPence,
                                        true,
                                      )}
                                    />
                                    {bidsNotifications?.[item.id]?.message && (
                                      <Row>
                                        <CommonButton
                                          label={bidsNotifications?.[item.id]?.message}
                                          variant={CommonButtonVariants.AUCTION}
                                          color={AuctionStatus.REJECTED}
                                        />
                                      </Row>
                                    )}
                                    <CommonButton
                                      label={
                                        highestBid
                                          ? BidStatusTypeName.REJECTED
                                          : `Your bid of ${preparePriceFormat(
                                              highestBidMyOrganization?.amountInPence,
                                              true,
                                            )} was not the highest.`
                                      }
                                      variant={CommonButtonVariants.AUCTION}
                                      color={
                                        highestBid
                                          ? AuctionStatus.REJECTED
                                          : AuctionStatus.DRAFT
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6} className="text-center">
                                <span className="auction-detail-buyer__section-title">
                                  Bids from your organisation:
                                </span>
                                <ul>{renderBidders()}</ul>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <CommonButton
                        label={BidStatusTypeName.REJECTED}
                        variant={CommonButtonVariants.AUCTION}
                        color={AuctionStatus.REJECTED}
                      />
                    )}
                  </Row>
                );
              case AuctionStatus.TO_ACCEPT:
                return (
                  <Row className="auction-detail-buyer-wrapper d-flex justify-content-start">
                    {bidsData.length && sellerOrganizationId !== organizationId ? (
                      <Formik initialValues={{ bid: '' }} onSubmit={null}>
                        {() => (
                          <Form className="auction-detail-bid__form">
                            <Row>
                              <Col md={6}>
                                <p className="text-center mt-2 mb-0 font-weight-bold">
                                  Last bid from your organisation:
                                </p>
                                <Row className="d-flex justify-content-center">
                                  <Col className="p-0">
                                    <FormCurrencyInputField
                                      inputClassName="font-weight-bold"
                                      name="bid"
                                      disabled
                                      className="auction-detail-bid__currency-input"
                                      placeholder={preparePriceFormat(
                                        highestBidMyOrganization?.amountInPence,
                                        true,
                                      )}
                                    />
                                    {bidMyOrganizationIsHighest ? (
                                      <CommonButton
                                        label="Waiting for the seller's acceptance"
                                        variant={CommonButtonVariants.AUCTION}
                                        color={AuctionStatus.WAITING}
                                      />
                                    ) : (
                                      <CommonButton
                                        label={`Your bid of ${preparePriceFormat(
                                          highestBidMyOrganization?.amountInPence,
                                          true,
                                        )} was not the highest.`}
                                        variant={CommonButtonVariants.AUCTION}
                                      />
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6} className="text-center">
                                <span className="auction-detail-buyer__section-title">
                                  Bids from your organisation:
                                </span>
                                <ul>{renderBidders()}</ul>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <p>No bids yet</p>
                    )}
                  </Row>
                );
              case AuctionStatus.WAITING:
                return (
                  <Row className="auction-detail-buyer-wrapper d-flex justify-content-start">
                    {bidsData.length && sellerOrganizationId !== organizationId ? (
                      <Formik initialValues={{ bid: '' }} onSubmit={null}>
                        {() => (
                          <Form className="auction-detail-bid__form">
                            <Row>
                              <Col md={6}>
                                <p className="text-center mt-2 mb-0 font-weight-bold">
                                  Last bid from your organisation:
                                </p>
                                <Row className="d-flex justify-content-center">
                                  <Col className="p-0">
                                    <FormCurrencyInputField
                                      inputClassName="font-weight-bold"
                                      name="bid"
                                      disabled
                                      className="auction-detail-bid__currency-input"
                                      placeholder={preparePriceFormat(
                                        highestBidMyOrganization?.amountInPence,
                                        true,
                                      )}
                                    />
                                    {item.isBuyerAcceptanceAllowed ? (
                                      <CommonButton
                                        label="Waiting for the buyer's acceptance"
                                        variant={CommonButtonVariants.AUCTION}
                                        color={AuctionStatus.TO_ACCEPT}
                                      />
                                    ) : (
                                      <CommonButton
                                        label={`Your bid of ${preparePriceFormat(
                                          highestBidMyOrganization?.amountInPence,
                                          true,
                                        )} was not the highest.`}
                                        variant={CommonButtonVariants.AUCTION}
                                      />
                                    )}
                                    {item?.isBuyerAcceptanceAllowed && (
                                      <div className="auction-details__buttons-section-wrapper auction-details__buttons-section-wrapper--buyer-waiting">
                                        <CommonButton
                                          label="Reject"
                                          color={AuctionStatus.REJECTED}
                                          handleClick={handleRejectAuction}
                                        />

                                        <CommonButton
                                          label="Accept"
                                          handleClick={handleAcceptAuction}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6} className="text-center">
                                <span className="auction-detail-buyer__section-title">
                                  Bids from your organisation:
                                </span>
                                <ul>{renderBidders()}</ul>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      ''
                    )}
                  </Row>
                );
              case AuctionStatus.SOLD: {
                const {
                  firstName,
                  lastName,
                  email,
                  phoneNumber,
                  organizationContact,
                } = item?.seller;
                const { address, address2, city, county, postalCode } = item?.location || {};
                const isPaymentInProgress =
                  !item?.seller?.firstName && !address && !organizationContact?.tradeName;
                return (
                  <>
                    {bidsData.length && sellerOrganizationId !== organizationId ? (
                      <Row className="auction-detail-buyer-wrapper">
                        {bidMyOrganizationIsHighest ? (
                          <>
                            <Col md={4}>
                              <p className="text-center mb-0">
                                <strong>Last bid from your organisation:</strong>
                              </p>
                              <Formik
                                initialValues={{ bid: '' }}
                                onSubmit={null}
                                validateOnBlur={true}
                              >
                                {() => (
                                  <Form>
                                    <FormInputField
                                      inputClassName="font-weight-bold"
                                      disabled
                                      name="bid"
                                      placeholder={preparePriceFormat(
                                        highestBidMyOrganization?.amountInPence,
                                        true,
                                      )}
                                    />
                                    <CommonButton
                                      label="Won"
                                      variant={CommonButtonVariants.AUCTION}
                                      color={AuctionStatus.SOLD}
                                    />
                                  </Form>
                                )}
                              </Formik>
                            </Col>
                            <Col
                              md={5}
                              className={`break-word ${
                                isPaymentInProgress
                                  ? 'd-flex justify-content-center align-items-center'
                                  : ''
                              }`}
                            >
                              <Row className="d-flex justify-content-between">
                                {!isPaymentInProgress ? (
                                  <>
                                    <Col
                                      md={6}
                                      className="d-flex flex-column justify-content-between"
                                    >
                                      <Row className="mb-2 d-flex flex-column ml-0 mr-0">
                                        <strong>Selling person information:</strong>
                                        <span>{`${firstName} ${lastName}`}</span>
                                        <span>{email}</span>
                                        <span>{phoneNumber}</span>
                                      </Row>

                                      <Row className="d-flex flex-column ml-0 mr-0">
                                        <strong>Location of the vehicle:</strong>
                                        <span>{address}</span>
                                        <span>{address2}</span>
                                        <span>{city}</span>
                                        <span>{county}</span>
                                        <span>{postalCode.postcode}</span>
                                      </Row>
                                    </Col>
                                    <Col md={6} className="d-flex flex-column">
                                      <strong>Dealer&apos;s information:</strong>
                                      <span>{organizationContact.tradeName}</span>
                                      <span>{organizationContact.location.address}</span>
                                      <span>{organizationContact.location?.address2}</span>
                                      <span>{organizationContact.location.city}</span>
                                      <span>{organizationContact.location.county}</span>
                                      <span>
                                        {organizationContact.location.postalCode.postcode}
                                      </span>
                                      <span>{organizationContact.email}</span>
                                      <span>{organizationContact.landlinePhoneNumber}</span>
                                    </Col>
                                  </>
                                ) : (
                                  <span>{StaticValues.PAYMENT_IN_PROGRESS}</span>
                                )}
                              </Row>
                            </Col>
                            <Col md={3}>
                              <span className="auction-detail-buyer__section-title">
                                Bids from your organisation:
                              </span>
                              <ul>{renderBidders()}</ul>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col md={6}>
                              <p className="text-center">
                                <strong>Last bid from your organisation:</strong>
                              </p>
                              <Formik
                                initialValues={{ bid: '' }}
                                onSubmit={null}
                                validateOnBlur={true}
                              >
                                {() => (
                                  <Form>
                                    <FormInputField
                                      inputClassName="font-weight-bold"
                                      disabled
                                      name="bid"
                                      placeholder={preparePriceFormat(
                                        highestBidMyOrganization?.amountInPence,
                                        true,
                                      )}
                                    />
                                    <CommonButton
                                      label={`Your bid of ${preparePriceFormat(
                                        highestBidMyOrganization?.amountInPence,
                                        true,
                                      )} was not the highest. The winning bid was ${preparePriceFormat(
                                        item?.proxyBidInPence ?? item?.wonBidPriceInPence,
                                        true,
                                      )}`}
                                      variant={CommonButtonVariants.AUCTION}
                                    />
                                  </Form>
                                )}
                              </Formik>
                            </Col>
                            <Col
                              md={6}
                              className="d-flex flex-column justify-content-center align-items-center"
                            >
                              <span className="auction-detail-buyer__section-title">
                                Bids from your organisation:
                              </span>
                              <ul>{renderBidders()}</ul>
                            </Col>
                          </>
                        )}
                      </Row>
                    ) : (
                      <Row className="auction-detail-buyer-wrapper">
                        <CommonButton
                          label="Sold"
                          variant={CommonButtonVariants.AUCTION}
                          color={AuctionStatus.SOLD}
                        />
                      </Row>
                    )}
                  </>
                );
              }
              case AuctionStatus.CANCELLED:
                return (
                  <Row className="auction-detail-buyer-wrapper">
                    {bidsData.length ? (
                      <>
                        <Col
                          md={6}
                          className="auction-detail-bid__form-inner-wrapper text-center"
                        >
                          <strong>Last bid from your organisation:</strong>
                          <Formik
                            initialValues={{ bid: '' }}
                            onSubmit={null}
                            validateOnBlur={true}
                          >
                            {() => (
                              <Form>
                                <FormInputField
                                  inputClassName="font-weight-bold"
                                  disabled
                                  name="bid"
                                  placeholder={preparePriceFormat(
                                    highestBidMyOrganization?.amountInPence,
                                    true,
                                  )}
                                />
                                <CommonButton
                                  label="Cancelled"
                                  variant={CommonButtonVariants.AUCTION}
                                  color={AuctionStatus.CANCELLED}
                                />
                              </Form>
                            )}
                          </Formik>
                        </Col>

                        <Col md={6} className="text-center">
                          <span className="auction-detail-buyer__section-title">
                            Bids from your organisation:
                          </span>
                          <ul>{renderBidders()}</ul>
                        </Col>
                      </>
                    ) : (
                      <CommonButton
                        label="Cancelled"
                        variant={CommonButtonVariants.AUCTION}
                        color={AuctionStatus.CANCELLED}
                      />
                    )}
                  </Row>
                );
              default:
                return null;
            }
          })()}
        </>
      ) : null}
      {showConfirm && (
        <SearchConfirmBidModal
          toggleShow={() => setShowConfirm(false)}
          placeBid={handleBid}
          bidAmount={bidToSend}
        />
      )}
    </>
  );
};

AuctionDetailsBuyerSection.defaultProps = {
  bids: [],
};

AuctionDetailsBuyerSection.propTypes = {
  item: PropTypes.object.isRequired,
  refreshItem: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  changeStatus: PropTypes.func.isRequired,
  bids: PropTypes.array,
};

export default AuctionDetailsBuyerSection;
