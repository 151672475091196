import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';
import PropTypes from 'prop-types';
import FormInputField from '../../common/components/formFields/FormInputField';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import FormCheckboxField from '../../common/components/formFields/FormCheckboxField';
import CommonButton from '../../common/components/CommonButton';
import { CommonButtonVariants } from '../../constants/CommonButtonVariants';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { DealerPositionName } from '../../constants/DealerPosition';
import { fetchOrganizationLocations } from '../SettingsActions';
import { AUTH_TOKEN } from '../../auth/AuthConstants';
import SettingsHeader from '../SettingsHeader';
import { AccountLayoutContentWrapper } from '../../common/components/AccountLayoutWrapper';

const dealerRole = constantToSelect(DealerPositionName);

const ProfileDetailsForm = ({ form }) => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const organizationLocations = useSelector(state =>
    state.myOrganization.get('organizationLocations'),
  );
  const [location, setLocation] = useState([]);
  const [isOwner, setIsOwner] = useState(true);

  useEffect(() => {
    dispatch(fetchOrganizationLocations());
    if (organizationLocations.length > 0) {
      const preparedOrganizationLocations = organizationLocations.map((item, index) => {
        return {
          label: `${item.city}, ${item.address} - ${
            item.isMain ? 'Main location' : `Additional location ${index + 1}`
          }`,
          value: item.id,
        };
      });
      setLocation(preparedOrganizationLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organizationLocations.length]);

  useEffect(() => {
    if (authToken) {
      const decoded = jwt.decode(authToken);
      const isOwner = decoded?.isOwner;
      setIsOwner(isOwner);
    }
  }, [authToken]);

  return (
    <>
      <SettingsHeader>
        <Row>
          <Col className="d-flex justify-content-end align-items-center actions-wrapper">
            <CommonButton
              className="text-sm"
              label="SAVE"
              variant={CommonButtonVariants.PRIMARY}
              disabled={isLoading}
              handleClick={() => form.handleSubmit()}
            />
          </Col>
        </Row>
      </SettingsHeader>
      <AccountLayoutContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold m-0">My Profile</h5>
            <p className="text-sm mt-2">
              Here you can change your login password and check the information of your profile
              is correct. If you wish to make any changes to your data, please contact Auction4Dealers
              support –{' '}
              <a href="mailto:contact@auction4dealers.co.uk" className="font-weight-bold">
                contact@auction4dealers.co.uk
              </a>
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="justify-content-center mr-auto ml-auto">
            <Form autoComplete="off" className="text-left">
              <Row className="justify-content-between">
                <Col md="4">
                  <FormInputField name="firstName" label="First name" disabled />
                </Col>
                <Col md="4">
                  <FormInputField name="lastName" label="Last name" disabled />
                </Col>
                <Col md="4">
                  <FormSelectField
                    name="position"
                    label="Position"
                    options={dealerRole}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="4">
                  <FormSelectField
                    name="location"
                    label="Location"
                    options={location}
                    disabled={isOwner}
                  />
                </Col>
                <Col md="4">
                  <FormInputField name="phoneNumber" label="Phone number" disabled />
                </Col>
                <Col md="4">
                  <FormInputField name="email" label="E-mail" disabled />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="4">
                  <FormInputField type="password" name="password" label="Password" />
                </Col>
                <Col md="4">
                  <FormInputField
                    type="password"
                    name="passwordRepeat"
                    label="Repeat password"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={12}>
                  <FormCheckboxField label="Sign me for Newsletter" name="acceptNewsletter" />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AccountLayoutContentWrapper>
    </>
  );
};

ProfileDetailsForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default ProfileDetailsForm;
