import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const WhoWeAreSection = () => {
  return (
    <section className="who-we-are section">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Row className="m-auto mb-5">
              <Col sm={12} className="pl-0">
                <h5 className="color-light-primary text-lg font-weight-bolder">WHO WE ARE</h5>
                <h5 className="font-weight-bold text-3xl mb-4">
                  Nulla sit vestibulum diam egestas. Amet sit risus lobortis eget augue. Rutrum
                  enim a in lectus gravida.
                </h5>
                <p className="color-primary fw-400 text-base">
                  Erat commodo, dui in cursus pharetra eget lorem. Congue sed suspendisse cras
                  id tellus amet imperdiet ut. Felis gravida dui sagittis, tempor risus at ut.
                  Eget risus id aliquet quam. Aliquam morbi lobortis augue pellentesque. Vel,
                  sed turpis sit semper. Scelerisque porta vel pellentesque fermentum, ipsum.
                  Diam sed commodo est est donec enim. Ac viverra ac ullamcorper a adipiscing
                  imperdiet. A justo dolor.
                </p>
              </Col>
            </Row>
            <Row className="d-flex who-we-are__tile-wrapper gx-4">
              <Col sm={12} md={3}>
                <div className="who-we-are__tile">
                  <div className="who-we-are__tile-title">5K+</div>
                  <div className="who-we-are__tile-description">
                    VEHICLES SOLD
                    <br /> THROUGH AUCTION4DEALERS
                  </div>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="who-we-are__tile">
                  <div className="who-we-are__tile-title">70+</div>
                  <div className="who-we-are__tile-description">
                    TRUSTED DEALERS
                    <br /> IN OUR DATABASE
                  </div>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="who-we-are__tile">
                  <div className="who-we-are__tile-title">200+</div>
                  <div className="who-we-are__tile-description">
                    LISTED OFFERS
                    <br /> PER MONTH
                  </div>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="who-we-are__tile">
                  <div className="who-we-are__tile-title">100</div>
                  <div className="who-we-are__tile-description">
                    AVERAGE NUMBER
                    <br />
                    OF VIEWS PER OFFER
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhoWeAreSection;
