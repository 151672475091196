import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import { Alert, Col, Row, FormGroup } from 'reactstrap';
import Select from 'react-select';
import {
  clearBidErrorMessage,
  placeBid,
  searchAuctions,
  setMakeId,
  setRefreshQuery,
  setSearchQuery,
} from './SearchActions';
import AuctionItem from './AuctionItem';
import InfinityLoader from '../common/components/InfinityLoader';
import { AuctionSortType, AuctionSortTypeName } from '../constants/AuctionSortType';
import { AUCTION_PUBLISHED, SSE_BUYER, SSE_SELLER, SSEClient } from '../api/SSEClient';
import SearchBidModal from './bidModals/SearchBidModal';
import {
  getAuction,
  handleAuctionEvent,
  refreshAuctionList,
} from '../myvehicles/MyVehiclesActions';
import { getAuctionsAggregatedByMake } from '../homepage/HomePageActions';
import { ListingType } from '../constants/ListingType';
import { closeAlert, showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';
import refreshIcon from '../assets/img/refreshIcon.png';
import { REFRESH_AUCTION_SUCCESS } from './SearchReducer';
import CommonButton from '../common/components/CommonButton';
import { clearCopiedSearchAlertState } from '../settings/searchalerts/SearchAlertActions';
import RouterPaths from '../constants/RouterPaths';
import {
  AccountLayoutTitle,
  AccountLayoutWrapper,
} from '../common/components/AccountLayoutWrapper';
import AuctionSearch from './AuctionSearch';
import defaultLogo from '../assets/img/favicon.png';

const sseClientSeller = new SSEClient(SSE_SELLER);
const sseClientBuyer = new SSEClient(SSE_BUYER);

const VehicleLogo = ({ logo, alt }) => {
  const [error, setError] = useState(false);

  return (
    <img
      className="mr-3"
      src={error ? defaultLogo : logo}
      alt={alt}
      onError={() => setError(true)}
    />
  );
};

const SearchPage = ({ history }) => {
  const dispatch = useDispatch();
  const itemsRef = useRef({});
  const headerRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState(AuctionSortType.ENDING_SOONEST);
  const [status, setStatus] = useState(null);
  const [auctions, setAuctions] = useState([]);
  const [showBidModal, setShowBidModal] = useState(false);
  const [currentBidAuction, setCurrentBidAuction] = useState(null);

  const [scrollTo, setScrollTo] = useState(null);
  const hasMore = useSelector(state => state.search.get('hasMore'));
  const refreshQuery = useSelector(state => state.search.get('refreshQuery'));
  const makeId = useSelector(state => state.search.get('makeId'));
  const searchQuery = useSelector(state => state.search.get('searchQuery'));
  const searchItems = useSelector(state => state.search.get('items').toJS());
  const searchTotalItems = useSelector(state => state.search.get('totalItems'));
  const organizationId = useSelector(state => state.auth.get('organizationId'));
  // const vehicleMakeVisible = useSelector(state => state.homePage.get('vehicleMakeVisible'));
  const aggregation = useSelector(state => state.homePage.get('aggregation'));

  const location = useLocation();

  const updateSearchItems = newItem => {
    dispatch({
      type: REFRESH_AUCTION_SUCCESS,
      payload: {
        item: newItem,
      },
    });
  };

  const clearAll = () => {
    scrollToHeader();
    setOffset(0);
    setScrollTo(null);
    setStatus(null);
    history.replace({ ...history.location, state: {} });
    setSort(AuctionSortType.ENDING_SOONEST);
  };

  useEffect(() => {
    dispatch(clearBidErrorMessage());
    dispatch(setSearchQuery('', true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    sseClientSeller.listen(
      auctions?.filter(item => item.organizationId === organizationId).map(item => item?.id),
      response => {
        handleAuctionEvent(response, () =>
          dispatch(
            getAuction(response.auctionId, auction => {
              updateSearchItems(auction);
            }),
          ),
        );
      },
    );
    return () => {
      sseClientSeller.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctions, organizationId, dispatch]);

  useEffect(() => {
    sseClientBuyer.listen(
      auctions.map(item => item.id),
      response => {
        if (response?.type === AUCTION_PUBLISHED) {
          return dispatch(
            refreshAuctionList(
              { auctionId: response?.auctionId, context: ListingType.SEARCH },
              () =>
                dispatch(
                  showAlert(
                    `<img class="refresh-icon" src=${refreshIcon} alt=""> New vehicle - click to refresh`,
                    AlertVariant.NEW_VEHICLE_AVAILABLE,
                    null,
                    true,
                    () => {
                      clearAll();
                      dispatch(setSearchQuery('', true));
                      dispatch(closeAlert());
                    },
                  ),
                ),
            ),
          );
        }
        return dispatch(
          handleAuctionEvent(
            response,
            () =>
              dispatch(
                getAuction(response.auctionId, auction => {
                  updateSearchItems(auction);
                }),
              ),
            dispatch(getAuctionsAggregatedByMake()),
          ),
        );
      },
    );
    return () => {
      sseClientBuyer.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctions, dispatch]);

  const _searchAuctions = () => {
    if (loading) return;
    setLoading(true);
    dispatch(
      searchAuctions(
        {
          search: searchQuery,
          makeId,
          offset: location?.state?.offset || offset,
          type: status,
        },
        sort,
        auctions => {
          setLoading(false);
          setAuctions(auctions);
          setLoaded(true);
          scrollToItem();
        },
        () => {
          setLoading(false);
        },
      ),
    );
  };

  useEffect(() => {
    if (!_.has(location, 'state.offset')) {
      clearAll();
    }
  }, []);

  useEffect(() => {
    dispatch(getAuctionsAggregatedByMake());
    setRefresh(refreshQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshQuery]);

  useEffect(() => {
    if (
      _.has(location, 'state.offset') &&
      _.has(location, 'state.auctionId') &&
      !_.isEmpty(searchItems)
    ) {
      const locationOffset = _.get(location, 'state.offset', 0);

      if (locationOffset !== 0) {
        setOffset(_.get(location, 'state.offset', 0) + 1);
      }

      setStatus(_.get(location, 'state.status', null));
      setScrollTo(location.state.auctionId);
      history.replace({ ...history.location, state: {} });
      return;
    }

    _searchAuctions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, offset, sort, dispatch, status, refresh]);

  const scrollToItem = () => {
    if (!scrollTo) return;
    if (itemsRef.current[scrollTo]) {
      itemsRef.current[scrollTo].scrollIntoView({ behavior: 'auto', block: 'center' });
      setScrollTo(null);
    }
  };

  const scrollToHeader = () => {
    if (!headerRef || !headerRef.current) return;
    headerRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
  };

  const loadData = () => {
    setOffset(prev => {
      return prev + 1;
    });
  };

  const handleChangeSort = option => {
    setSort(option?.value || AuctionSortType.ENDING_SOONEST);
    setOffset(0);
  };

  // const handleChangeSortByStatusFilter = type => {
  //   setStatus(type);
  //   setOffset(0);
  // };

  const handleBidModal = item => {
    setCurrentBidAuction(item);
    setShowBidModal(true);
  };

  const toggleBidModal = () => {
    setShowBidModal(false);
  };

  const handlePlaceBid = amount => {
    dispatch(placeBid(currentBidAuction?.id, amount * 100));
  };

  const setRef = (ref, id) => {
    itemsRef.current[id] = ref;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchByMake = id => {
    dispatch(setMakeId(id === makeId ? null : id));
    if (location?.state?.offset) {
      location.state.offset = 0;
    }
    setOffset(0);
    dispatch(setRefreshQuery());
  };

  const navItems = useCallback(() => {
    return (
      aggregation?.map(agg => {
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            key={agg?.makeId || 0}
            className={`account-layout__nav-item search-nav-item ${
              makeId === agg?.makeId ? 'active' : ''
            }`}
            onClick={() => handleSearchByMake(agg?.makeId)}
          >
            <VehicleLogo logo={agg?.urlToLogo} alt={agg?.make || 'icon'} />
            <span>{agg?.make || ''}</span>
            <span className="counter-box box-primary ml-auto font-weight-bold">
              {agg?.count || 0}
            </span>
          </div>
        );
      }) || []
    );
  }, [aggregation, handleSearchByMake, makeId]);

  return (
    <AccountLayoutWrapper
      contentClassName="content-column__listing"
      navTitle={
        <AccountLayoutTitle>
          {searchTotalItems} {`${searchTotalItems === 1 ? 'VEHICLE' : 'VEHICLES'}`}
        </AccountLayoutTitle>
      }
      navOptions={navItems()}
    >
      {loaded && (
        <>
          <div ref={headerRef} />
          <Row className="auction-detail-header mb-3">
            <Col md={12} className="p-0 d-flex flex-row">
              <h4 className="m-0 font-weight-bold page-title">Live Auctions</h4>
              <div className="ml-auto d-flex flex-row auction-detail-header__actions-container">
                <AuctionSearch />
                <FormGroup className="m-0">
                  <Select
                    options={[
                      {
                        value: AuctionSortType.ENDING_SOONEST,
                        label: AuctionSortTypeName.ENDING_SOONEST,
                      },
                      {
                        value: AuctionSortType.LATEST_ADDED,
                        label: AuctionSortTypeName.LATEST_ADDED,
                      },
                    ]}
                    onChange={handleChangeSort}
                    placeholder="Sort by"
                    classNamePrefix="react-select"
                    defaultValue={{
                      value: AuctionSortType.ENDING_SOONEST,
                      label: AuctionSortTypeName.ENDING_SOONEST,
                    }}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          {searchTotalItems > 0 ? (
            <InfiniteScroll
              next={loadData}
              hasMore={hasMore}
              loader={<InfinityLoader />}
              dataLength={searchItems.length}
            >
              {searchItems.map(item => {
                if (item?.status === 'PUBLISHED') {
                  return (
                    <AuctionItem
                      locationState={{
                        offset,
                        status,
                        auctionId: item.id,
                      }}
                      getRef={el => setRef(el, item.id)}
                      item={item}
                      key={item.id}
                      handleBid={handleBidModal}
                      showBidModal={showBidModal}
                    />
                  );
                }
                return null;
              })}
            </InfiniteScroll>
          ) : (
            !loading && (
              <Alert color="info">
                {searchQuery ? (
                  <p className="m-0">
                    Oops, we could not find any result for the given phrase.
                    <br />
                    Click the add button to set a new search alert and we will inform you about
                    any new vehicles that match your criteria.
                  </p>
                ) : (
                  <>
                    {status ? (
                      <p className="m-0">
                        Oops, we could not find any result for the given filter.
                        <br />
                        Click the add button to set a new search alert and we will inform you
                        about any new vehicles that match your criteria.
                      </p>
                    ) : (
                      !loading &&
                      loaded && (
                        <p className="m-0">
                          We&apos;ve Sold Everything!
                          <br />
                          But don&apos;t Worry, There&apos;s More stock arriving daily.
                          <br />
                          <br />
                          Make Sure You{' '}
                          <span className="font-weight-bold">DOWNLOAD THE APP</span> To Receive
                          Notifications For The Latest Vehicles Added
                        </p>
                      )
                    )}
                    {searchQuery ||
                      (status && (
                        <div className="d-flex justify-content-center">
                          <CommonButton
                            type="submit"
                            label="Add"
                            handleClick={() => {
                              dispatch(clearCopiedSearchAlertState());
                              history.push(RouterPaths.CREATE_SEARCH_ALERT);
                            }}
                          />
                        </div>
                      ))}
                  </>
                )}
              </Alert>
            )
          )}
        </>
      )}
      {showBidModal && (
        <SearchBidModal
          toggleShow={toggleBidModal}
          item={currentBidAuction}
          handlePlaceBid={handlePlaceBid}
        />
      )}
    </AccountLayoutWrapper>
  );
};
export default withRouter(SearchPage);
