import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { TyreDepths } from '../../constants/TyreDepths';
import { getDraft, saveTyresDraft, validateTyres } from '../AddVehicleActions';
import validationSchema from './TyreTreadDepthsForm.schema';
import AddVehicleHeader from '../AddVehicleHeader';
import AddVehicleContentWrapper from '../AddVehicleContentWrapper';
import tireDepthImage from '../../assets/img/vehicles/tire-top-view.png';
import topViewVehicleImage from '../../assets/img/vehicles/top-view-transparent.png';
import AddVehicleFooter from '../AddVehicleFooter';

const tyreDepths = constantToSelect(TyreDepths);

const TyreTreadDepthsForm = ({ form, prevForm, nextForm, values }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.navbarLoader.get('isLoading'));
  const id = useSelector(state => state.addVehicle.get('id'));

  const saveDraft = () => {
    const tyreThreadDepths = {
      nearsideFront: values?.nearsideFront?.label,
      nearsideRear: values?.nearsideRear?.label,
      offsideFront: values?.offsideFront?.label,
      offsideRear: values?.offsideRear?.label,
    };
    dispatch(saveTyresDraft(tyreThreadDepths, () => dispatch(getDraft(id))));
    form.setErrors({});
  };

  const handleNextButton = useCallback(async () => {
    form.setSubmitting(true);
    await dispatch(validateTyres(form.values, () => nextForm()));
    form.setSubmitting(false);
  }, [form, dispatch, nextForm]);

  const handleSelectFieldChange = (name, value) => {
    if (value) {
      const parsedValue = parseInt(value, 10);
      const foundValue = tyreDepths.find(it => it.label === parsedValue);
      if (foundValue) {
        form.setFieldValue(name, foundValue);
      } else {
        form.setFieldValue(name, '');
      }
    }
  };

  return (
    <>
      <AddVehicleHeader
        nextButtonHandler={handleNextButton}
        backButtonHandler={prevForm}
        saveDraftButtonHandler={saveDraft}
      />
      <AddVehicleContentWrapper>
        <Row>
          <Col>
            <h5 className="font-weight-bold">Tyres</h5>
            <p className="text-sm">Add specific tyre tread depths.</p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="justify-content-center mr-auto ml-auto">
            <Form className="add-tyres-form">
              <Row className="d-flex justify-content-between add-tyres-form__top-row">
                <Col
                  sm={12}
                  md={6}
                  className="d-flex align-items-center add-tyres-form__tyre-container"
                >
                  <FormSelectField
                    name="nearsideFront"
                    label="Nearside front (mm)"
                    options={tyreDepths}
                    className="w-100"
                    required
                    selectProps={{
                      maxMenuHeight: 150,
                      onInputChange: value => handleSelectFieldChange('nearsideFront', value),
                    }}
                  />
                  <img
                    src={tireDepthImage}
                    className="tyre-container__image-left"
                    alt="Tire depth"
                    width={32}
                    height={80}
                  />
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="d-flex align-items-center add-tyres-form__tyre-container"
                >
                  <img
                    src={tireDepthImage}
                    alt="Tire depth"
                    className="tyre-container__image-right"
                    width={32}
                    height={80}
                  />
                  <FormSelectField
                    name="offsideFront"
                    label="Offside front (mm)"
                    options={tyreDepths}
                    className="w-100"
                    required
                    selectProps={{
                      maxMenuHeight: 150,
                      onInputChange: value => handleSelectFieldChange('offsideFront', value),
                    }}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-between add-tyres-form__lower-row">
                <Col
                  sm={12}
                  md={6}
                  className="d-flex align-items-center add-tyres-form__tyre-container"
                >
                  <FormSelectField
                    name="nearsideRear"
                    label="Nearside rear (mm)"
                    options={tyreDepths}
                    className="w-100"
                    required
                    selectProps={{
                      maxMenuHeight: 150,
                      onInputChange: value => handleSelectFieldChange('nearsideRear', value),
                    }}
                  />
                  <img
                    src={tireDepthImage}
                    alt="Tire depth"
                    className="tyre-container__image-left"
                    width={32}
                    height={80}
                  />
                </Col>
                <Col
                  sm={12}
                  md={6}
                  className="d-flex align-items-center add-tyres-form__tyre-container"
                >
                  <img
                    src={tireDepthImage}
                    alt="Tire depth"
                    className="tyre-container__image-right"
                    width={32}
                    height={80}
                  />
                  <FormSelectField
                    name="offsideRear"
                    label="Offside rear (mm)"
                    options={tyreDepths}
                    className="w-100"
                    required
                    selectProps={{
                      maxMenuHeight: 150,
                      onInputChange: value => handleSelectFieldChange('offsideRear', value),
                    }}
                  />
                </Col>
              </Row>
              <div className="add-tyres-form__middle-image-container">
                <img src={topViewVehicleImage} alt="Add Tyres" />
              </div>
            </Form>
          </Col>
        </Row>
      </AddVehicleContentWrapper>
      <AddVehicleFooter
        nextButtonHandler={() => {
          saveDraft();
          nextForm();
        }}
        backButtonHandler={prevForm}
        nextButtonDisabled={!!isLoading}
      />
    </>
  );
};

const TyreTreadDepths = ({ nextForm, prevForm }) => {
  const tyreThreadDepths = useSelector(state => state.addVehicle.get('tyreThreadDepths'));

  const prepareTyreThreadDepths = () => {
    if (tyreThreadDepths) {
      const tyreThreadDepthsObj = {};
      Object.keys(tyreThreadDepths).forEach(key => {
        tyreThreadDepthsObj[key] = {
          value: tyreThreadDepths[key],
          label: tyreThreadDepths[key],
        };
      });
      return tyreThreadDepthsObj;
    }
    return '';
  };

  const initialValues = {
    ...prepareTyreThreadDepths(),
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        {form => (
          <TyreTreadDepthsForm
            form={form}
            nextForm={nextForm}
            prevForm={prevForm}
            values={form.values}
          />
        )}
      </Formik>
    </>
  );
};

TyreTreadDepths.propTypes = {
  prevForm: PropTypes.func.isRequired,
  nextForm: PropTypes.func.isRequired,
};

export default TyreTreadDepths;
