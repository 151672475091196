import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { PhotoUploadState } from 'itrade-admin-panel/src/constants/PhotoUploadState';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';
import exclamationIcon from '../../assets/img/icons/exclamation.svg';
import { checkPhotoStep } from '../helpers/checkPhotoStep';

import {
  getDraft,
  removePhotos,
  saveAddPhotoDraft,
  sendVehiclePhoto,
  setFormStep,
  setPhotoStep,
  updatePhotos,
} from '../AddVehicleActions';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';
import CommonShadowLoader, {
  CommonShadowLoaderType,
} from '../../common/components/CommonShadowLoader';
import arrowsIcon from '../../assets/img/icons/arrows-blue.svg';
import { getPhotoDescription } from '../../common/helpers/getPhotoDescription';
import { PhotoPerspective } from '../../constants/PhotoPerspective';
import plusIcon from '../../assets/img/plus-icon.png';
import CommonDeleteIconButton from '../../common/components/CommonDeleteIconButton';
import CommonEditIconButton from '../../common/components/CommonEditIconButton';
import { CLEAR_PHOTOS_TO_DELETE } from '../AddVehicleReducer';

const AddPhotoSummary = ({ nextForm }) => {
  const dispatch = useDispatch();
  const id = useSelector(state => state.addVehicle.get('id'));
  const photos = Array.from(useSelector(state => state.addVehicle.get('photos')));
  const photosToDelete = useSelector(state => state.addVehicle.get('photosToDelete')) || [];
  const sentPhotos = Array.from(useSelector(state => state.addVehicle.get('photos')));
  const vehicleMedia = useSelector(state => state.addVehicle.get('vehicleMedia'));
  const [gridColumnCount, setGridColumnCount] = useState(3);
  const sendingPhotos = useRef(false);

  const updateDimensions = () => {
    if (window.innerWidth > 767) {
      setGridColumnCount(3);
    } else {
      setGridColumnCount(1);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (sendingPhotos.current) return;
    sendingPhotos.current = true;
    const photosToSave = photos.map((item, index) => {
      if (item.status === PhotoUploadState.PENDING && item.file) {
        return dispatch(sendVehiclePhoto(item.file, photos, index, null, true)).then(
          () => true,
        );
      }
      return null;
    });
    Promise.all(photosToSave).then(res => {
      if (res.some(item => item === true)) {
        dispatch(saveAddPhotoDraft())
          .then(() => dispatch(getDraft(id)))
          .then(() => {
            sendingPhotos.current = false;
          });
      } else {
        sendingPhotos.current = false;
      }
    });
  }, [dispatch, photos, id]);

  useEffect(
    () => () => {
      dispatch({
        type: CLEAR_PHOTOS_TO_DELETE,
      });
    },
    [],
  );

  useEffect(() => {
    if (!photos?.length && photosToDelete?.length) {
      nextForm(4);
    }
  }, [photos, photosToDelete]);

  const handleEditPhotoClick = (e, item) => {
    saveAndGet();
    dispatch(setPhotoStep(checkPhotoStep(item)));
    dispatch(setPhotoStep(+e?.currentTarget?.dataset?.index + 1));
    dispatch(setFormStep(4));
  };

  const handleAddAdditionalPhoto = () => {
    if (canAddMorePhotos()) {
      dispatch(setPhotoStep(photos?.length + 1));
      dispatch(setFormStep(4));
    }
  };

  const canEdit = item => {
    if (item.status === PhotoUploadState.IN_PROGRESS) return false;
    return item.status !== PhotoUploadState.PENDING;
  };

  const getLoaderStatusByPhotoStatus = photoStatus => {
    switch (photoStatus) {
      case PhotoUploadState.PENDING:
        return CommonShadowLoaderType.PENDING;
      case PhotoUploadState.IN_PROGRESS:
        return CommonShadowLoaderType.IN_PROGRESS;
      default:
        return CommonShadowLoaderType.NOT_PENDING;
    }
  };

  const handleDelete = index => {
    dispatch(removePhotos(index, sentPhotos, vehicleMedia, photosToDelete));
    setTimeout(() => {
      saveAndGet();
    }, 100);
  };

  function saveAndGet() {
    dispatch(saveAddPhotoDraft()).then(() => dispatch(getDraft(id)));
  }

  function onChange(sourceId, sourceIndex, targetIndex) {
    const nextState = swap(photos, sourceIndex, targetIndex);
    nextState.forEach((item, index) => {
      item.perspective = getPhotoDescription(index + 1).PERSPECTIVE;
      item.sortNumber = index;
    });
    dispatch(updatePhotos(nextState));
  }

  const handleAddObligatoryPhoto = e => {
    const photoIndex = e?.currentTarget?.dataset?.index;
    dispatch(setPhotoStep(+photoIndex));
    dispatch(setFormStep(4));
  };

  const canAddMorePhotos = useCallback(() => {
    const addedPhotos = photos?.filter(el => el?.fileUrl) || [];
    return addedPhotos?.length >= REQUIRED_PHOTOS_AMOUNT;
  }, [photos]);

  return (
    <>
      <Row>
        <Col>
          <h5 className="font-weight-bold m-0">Photos</h5>
          <p className="text-sm mt-2">
            {`A Minimum of ${REQUIRED_PHOTOS_AMOUNT} images is required to proceed. Hold & Drag images to re-order.`}
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="p-0">
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="items"
              boxesPerRow={gridColumnCount}
              rowHeight={220}
              style={{
                height: `${220 * Math.ceil(((photos.length || 0) + 1) / gridColumnCount) +
                  30}px`,
              }}
            >
              {photos.length >= REQUIRED_PHOTOS_AMOUNT
                ? photos.map((item, index) => (
                    <GridItem key={item.id || uuid()}>
                      {item?.fileUrl ? (
                        <>
                          <div className="photo-summary__drag-drop-holder" />
                          <div
                            key={item?.id || uuid()}
                            className="photo-summary__photo-wrapper"
                          >
                            <CommonShadowLoader
                              status={getLoaderStatusByPhotoStatus(item.status)}
                            >
                              <div className="position-relative w-100 h-100 d-flex justify-content-center align-items-center">
                                <div className="overflow-hidden bg-white h-100 w-100">
                                  <img
                                    src={item?.fileUrl}
                                    alt="Vehicle"
                                    className="photo-summary__photo no-selectable"
                                  />
                                </div>
                                {canEdit(item) && (
                                  <>
                                    <div className="photo-summary__drag-position no-selectable">
                                      <img src={arrowsIcon} height={24} width={24} alt="alt" />
                                    </div>
                                    <div className="photo-summary__photo-icon-button-container no-selectable">
                                      <CommonDeleteIconButton
                                        className="photo-summary__photo-icon-button"
                                        dataIndex={index}
                                        handleClick={() => handleDelete(index)}
                                      />
                                      <CommonEditIconButton
                                        className="photo-summary__photo-icon-button"
                                        dataIndex={index}
                                        handleClick={e => handleEditPhotoClick(e, item)}
                                      />
                                    </div>
                                  </>
                                )}
                                {item.perspective !== PhotoPerspective.MASTER &&
                                  item?.damages?.length > 0 && (
                                    <div className="photo-summary__photo-damages-icon no-selectable">
                                      <img
                                        width={32}
                                        height={32}
                                        src={exclamationIcon}
                                        alt="Warning icon"
                                      />
                                    </div>
                                  )}
                              </div>
                            </CommonShadowLoader>
                          </div>
                        </>
                      ) : (
                        <div className="photo-summary__add-obligatory-photo-wrapper">
                          <button
                            key={item?.id || uuid()}
                            type="button"
                            data-index={index + 1}
                            // className="photo-summary__add-obligatory-photo-button m-1"
                            className="add-photo__base-style photo-summary__add-obligatory-photo-button"
                            onClick={handleAddObligatoryPhoto}
                          >
                            <p className="add-photo__drop-text m-0">CLICK TO BROWSE OR</p>
                            <p className="add-photo__drop-text m-0">DRAG & DROP PHOTO HERE</p>
                            <div className="add-photo__add_center-icon mt-3">
                              <img src={plusIcon} alt="Add" />
                            </div>
                          </button>
                        </div>
                      )}
                    </GridItem>
                  ))
                : null}
              <GridItem key={uuid()} onMouseDown={e => e.stopPropagation()}>
                <div className="photo-summary__photo-wrapper">
                  <button
                    type="button"
                    className="add-photo__base-style photo-summary__add-additional-photo-button"
                    onClick={canAddMorePhotos ? handleAddAdditionalPhoto : () => {}}
                  >
                    <p className="add-photo__drop-text m-0">CLICK TO BROWSE OR</p>
                    <p className="add-photo__drop-text m-0">DRAG & DROP PHOTO HERE</p>
                    <div
                      className={`add-photo__add_center-icon ${
                        canAddMorePhotos() ? '' : 'photo-place-grayed'
                      } mt-3`}
                    >
                      <img src={plusIcon} alt="Add" />
                    </div>
                  </button>
                </div>
              </GridItem>
            </GridDropZone>
          </GridContextProvider>
        </Col>
      </Row>
    </>
  );
};

export default AddPhotoSummary;
