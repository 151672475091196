import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import RouterPaths from '../../constants/RouterPaths';
import heroImage from '../../assets/img/HomePage/blue-hero-car.png';

const HeroSection = () => {
  return (
    <section className="hero section">
      <Container className="hero-content h-100" fluid>
        <div className="hero-title fw-700 text-left section-hero-title color-primary">
          Buy Trade Vehicles Exclusively From <br /> Franchised Main Dealers
        </div>
        <h3 className="hero-subtitle color-primary text-lg">
          Register Now for your 3 month <b>Free Trial</b>
        </h3>
        <Row className="hero-register-box">
          <Col lg="3" md="4" sm="6" className="hero-right">
            <NavLink
              to={RouterPaths.REGISTER}
              className="common-button primary-button auth-button w-100 m-0"
              activeClassName="auth-button--active"
            >
              FREE TRIAL
            </NavLink>
          </Col>
        </Row>
      </Container>
      <div className="hero-right-background" />
      <img src={heroImage} alt="register bid" className="hero-content-image" />
    </section>
  );
};

export default HeroSection;
